import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import gallery0 from "assets/img/gallery/gallery0.jpg";
import gallery1 from "assets/img/gallery/gallery1.jpg";
import gallery2 from "assets/img/gallery/gallery2.jpg";
import gallery3 from "assets/img/gallery/gallery3.jpg";
import gallery4 from "assets/img/gallery/gallery4.jpg";
import gallery5 from "assets/img/gallery/gallery5.jpg";
import gallery6 from "assets/img/gallery/gallery6.jpg";
import gallery7 from "assets/img/gallery/gallery7.jpg";
import gallery8 from "assets/img/gallery/gallery8.jpg";
import gallery9 from "assets/img/gallery/gallery9.jpg";
import gallery10 from "assets/img/gallery/gallery10.jpg";
import gallery11 from "assets/img/gallery/gallery11.jpg";
import gallery12 from "assets/img/gallery/gallery12.jpg";
import gallery13 from "assets/img/gallery/gallery13.jpg";
import gallery14 from "assets/img/gallery/gallery14.jpg";
import gallery15 from "assets/img/gallery/gallery15.jpg";
import gallery16 from "assets/img/gallery/gallery16.jpg";
import gallery17 from "assets/img/gallery/gallery17.jpg";
import gallery18 from "assets/img/gallery/gallery18.jpg";
import gallery19 from "assets/img/gallery/gallery19.jpg";
import gallery20 from "assets/img/gallery/gallery20.jpg";
import gallery21 from "assets/img/gallery/gallery21.jpg";
import gallery22 from "assets/img/gallery/gallery22.jpg";
import gallery23 from "assets/img/gallery/gallery23.jpg";
import gallery24 from "assets/img/gallery/gallery24.jpg";
import gallery25 from "assets/img/gallery/gallery25.jpg";
import gallery26 from "assets/img/gallery/gallery26.jpg";
import gallery27 from "assets/img/gallery/gallery27.jpg";
import gallery28 from "assets/img/gallery/gallery28.jpg";
import gallery29 from "assets/img/gallery/gallery29.jpg";
import gallery30 from "assets/img/gallery/gallery30.jpg";
import gallery31 from "assets/img/gallery/gallery31.jpg";
import gallery32 from "assets/img/gallery/gallery32.jpg";
import gallery33 from "assets/img/gallery/gallery33.jpg";
import gallery34 from "assets/img/gallery/gallery34.jpg";
import gallery35 from "assets/img/gallery/gallery35.jpg";
import gallery36 from "assets/img/gallery/gallery36.jpg";
import gallery37 from "assets/img/gallery/gallery37.jpg";
import gallery38 from "assets/img/gallery/gallery38.jpg";
import gallery39 from "assets/img/gallery/gallery39.jpg";
import gallery40 from "assets/img/gallery/gallery40.jpg";
import gallery41 from "assets/img/gallery/gallery41.jpg";
import gallery42 from "assets/img/gallery/gallery42.jpg";
import gallery43 from "assets/img/gallery/gallery43.jpg";
import gallery44 from "assets/img/gallery/gallery44.jpg";
import gallery45 from "assets/img/gallery/gallery45.jpg";
import gallery46 from "assets/img/gallery/gallery46.jpg";
import gallery47 from "assets/img/gallery/gallery47.jpg";
import gallery48 from "assets/img/gallery/gallery48.jpg";
import gallery49 from "assets/img/gallery/gallery49.jpg";
import gallery50 from "assets/img/gallery/gallery50.jpg";
import gallery51 from "assets/img/gallery/gallery51.jpg";
import gallery52 from "assets/img/gallery/gallery52.jpg";
import gallery53 from "assets/img/gallery/gallery53.jpg";
import gallery54 from "assets/img/gallery/gallery54.jpg";
import gallery55 from "assets/img/gallery/gallery55.jpg";
import gallery56 from "assets/img/gallery/gallery56.jpg";
import gallery57 from "assets/img/gallery/gallery57.jpg";
import gallery58 from "assets/img/gallery/gallery58.jpg";
import gallery59 from "assets/img/gallery/gallery59.jpg";
import gallery60 from "assets/img/gallery/gallery60.jpg";
import gallery61 from "assets/img/gallery/gallery61.jpg";
import gallery62 from "assets/img/gallery/gallery62.jpg";
import gallery63 from "assets/img/gallery/gallery63.jpg";
import gallery64 from "assets/img/gallery/gallery64.jpg";
import gallery65 from "assets/img/gallery/gallery65.jpg";
import gallery66 from "assets/img/gallery/gallery66.jpg";
import gallery67 from "assets/img/gallery/gallery67.jpg";
import gallery68 from "assets/img/gallery/gallery68.jpg";
import gallery69 from "assets/img/gallery/gallery69.jpg";
import gallery70 from "assets/img/gallery/gallery70.jpg";
import gallery71 from "assets/img/gallery/gallery71.jpg";
import gallery72 from "assets/img/gallery/gallery72.jpg";
import gallery73 from "assets/img/gallery/gallery73.jpg";
import gallery74 from "assets/img/gallery/gallery74.jpg";
import gallery75 from "assets/img/gallery/gallery75.jpg";
import gallery76 from "assets/img/gallery/gallery76.jpg";
import gallery77 from "assets/img/gallery/gallery77.jpg";
import gallery78 from "assets/img/gallery/gallery78.jpg";

import styles from "assets/jss/material-kit-react/views/landingPageSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
  };

  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Gallery</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <img src={gallery0} className="slick-image" />
                <img src={gallery1} className="slick-image" />
                <img src={gallery2} className="slick-image" />
                <img src={gallery3} className="slick-image" />
                <img src={gallery4} className="slick-image" />
                <img src={gallery5} className="slick-image" />
                <img src={gallery6} className="slick-image" />
                <img src={gallery7} className="slick-image" />
                <img src={gallery8} className="slick-image" />
                <img src={gallery9} className="slick-image" />
                <img src={gallery10} className="slick-image" />
                <img src={gallery11} className="slick-image" />
                <img src={gallery12} className="slick-image" />
                <img src={gallery13} className="slick-image" />
                <img src={gallery14} className="slick-image" />
                <img src={gallery15} className="slick-image" />
                <img src={gallery16} className="slick-image" />
                <img src={gallery17} className="slick-image" />
                <img src={gallery18} className="slick-image" />
                <img src={gallery19} className="slick-image" />
                <img src={gallery20} className="slick-image" />
                <img src={gallery21} className="slick-image" />
                <img src={gallery22} className="slick-image" />
                <img src={gallery23} className="slick-image" />
                <img src={gallery24} className="slick-image" />
                <img src={gallery25} className="slick-image" />
                <img src={gallery26} className="slick-image" />
                <img src={gallery27} className="slick-image" />
                <img src={gallery28} className="slick-image" />
                <img src={gallery29} className="slick-image" />
                <img src={gallery30} className="slick-image" />
                <img src={gallery31} className="slick-image" />
                <img src={gallery32} className="slick-image" />
                <img src={gallery33} className="slick-image" />
                <img src={gallery34} className="slick-image" />
                <img src={gallery35} className="slick-image" />
                <img src={gallery36} className="slick-image" />
                <img src={gallery37} className="slick-image" />
                <img src={gallery38} className="slick-image" />
                <img src={gallery39} className="slick-image" />
                <img src={gallery40} className="slick-image" />
                <img src={gallery41} className="slick-image" />
                <img src={gallery42} className="slick-image" />
                <img src={gallery43} className="slick-image" />
                <img src={gallery44} className="slick-image" />
                <img src={gallery45} className="slick-image" />
                <img src={gallery46} className="slick-image" />
                <img src={gallery47} className="slick-image" />
                <img src={gallery48} className="slick-image" />
                <img src={gallery49} className="slick-image" />
                <img src={gallery50} className="slick-image" />
                <img src={gallery51} className="slick-image" />
                <img src={gallery52} className="slick-image" />
                <img src={gallery53} className="slick-image" />
                <img src={gallery54} className="slick-image" />
                <img src={gallery55} className="slick-image" />
                <img src={gallery56} className="slick-image" />
                <img src={gallery57} className="slick-image" />
                <img src={gallery58} className="slick-image" />
                <img src={gallery59} className="slick-image" />
                <img src={gallery60} className="slick-image" />
                <img src={gallery61} className="slick-image" />
                <img src={gallery62} className="slick-image" />
                <img src={gallery63} className="slick-image" />
                <img src={gallery64} className="slick-image" />
                <img src={gallery65} className="slick-image" />
                <img src={gallery66} className="slick-image" />
                <img src={gallery67} className="slick-image" />
                <img src={gallery68} className="slick-image" />
                <img src={gallery69} className="slick-image" />
                <img src={gallery70} className="slick-image" />
                <img src={gallery71} className="slick-image" />
                <img src={gallery72} className="slick-image" />
                <img src={gallery73} className="slick-image" />
                <img src={gallery74} className="slick-image" />
                <img src={gallery75} className="slick-image" />
                <img src={gallery76} className="slick-image" />
                <img src={gallery77} className="slick-image" />
                <img src={gallery78} className="slick-image" />
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
