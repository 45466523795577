import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Email, People } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "assets/jss/material-kit-react/views/contactPage.js";

const useStyles = makeStyles(styles);

export default function ContactForm() {
  const classes = useStyles();

  const [state, handleSubmit] = useForm("xaypaakq");
  const recaptchaRef = React.createRef();

  const onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const token = await recaptchaRef.current.executeAsync();
    formData.set("g-recaptcha-response", token);
    handleSubmit(formData);
  };

  var form = null;
  if (state.succeeded) {
    form = (
      <p className={classes.thanks}>
        Thank you for your message.
        <br /> We will contact you shortly!
      </p>
    );
  } else {
    form = (
      <>
        <p className={classes.divider}>Or Send Us a Message</p>
        <form className={classes.form} onSubmit={onSubmitWithReCAPTCHA}>
          <CardBody>
            <CustomInput
              labelText="Name..."
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                id: "name",
                name: "name",
                type: "text",
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
            <CustomInput
              labelText="Email..."
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                id: "email",
                name: "email",
                type: "email",
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <CustomInput
              labelText="Your Message"
              formControlProps={{
                fullWidth: true,
                className: classes.textArea,
              }}
              inputProps={{
                id: "message",
                name: "message",
                multiline: true,
                rows: 5,
              }}
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfYBo8aAAAAAO8gK1iLOMI9ISolVIzoB5xam74U"
              size="invisible"
            />
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button
              color="success"
              size="lg"
              type="submit"
              disabled={state.submitting}
            >
              Send
            </Button>
          </CardFooter>
        </form>
      </>
    );
  }
  return form;
}
