import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";

// @material-ui/icons
// import School from "@material-ui/icons/School";
// import EmojiEmotions from "@material-ui/icons/EmojiEmotions";
// import Build from "@material-ui/icons/Build";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();

  // const houzzLink = (
  //   <a
  //     target="_blank"
  //     rel="noreferrer"
  //     href="https://www.houzz.com/professionals/swimming-pool-builders/custom-pools-and-landscapes-of-richmond-pfvwus-pf~1350566327?"
  //   >
  //     Houzz
  //   </a>
  // );
  // const instagramLink = (
  //   <a
  //     target="_blank"
  //     rel="noreferrer"
  //     href="https://www.instagram.com/custompoolsandlandscapesofrva/"
  //   >
  //     Instagram
  //   </a>
  // );
  // const quality = (
  //   <>
  //     We pride ourselves in our work, and know that customer satisfaction is the
  //     ultimate measure of a job well done. Visit our {houzzLink} to see customer
  //     testimonials, or view our work in the gallery below or on {instagramLink}.
  //   </>
  // );

  // const contactLink = <Link to="/contact">contact us</Link>;
  // const contact = (
  //   <>
  //     We specialize in making our customers{"'"} visions into reality. If you
  //     have a unique vision for your property, {contactLink} today! From
  //     sprawling pools with built-in spas and water features to eco-friendly
  //     rainwater retention units, we can do it all.
  //   </>
  // );

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            One company. One contract. No worries.
          </h2>
          <h5 className={classes.description}>
            We think that clients should expect more from their contractors. As
            one of Central Virginia{"'"}s premier outdoor contractors, we have
            over 30 years of experience creating high-quality outdoor projects
            tailored exactly to our clients{"'"} expectations. It{"'"}s simple:
            We do it all, and we do it well.
          </h5>
        </GridItem>
      </GridContainer>
      {/* <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Experience"
              description={`
                Our team has over 30 years of experience contracting in Central Virginia.
                Our owner, Jim Farinholt, has won numerous awards at the Maymont Flower and Garden Show,
                and has been officially recognized by the Commonwealth of Virginia for the landscape 
                renovation of Richmond City Hall.
              `}
              icon={School}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Quality"
              description={quality}
              icon={EmojiEmotions}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Custom-Made"
              description={contact}
              icon={Build}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div> */}
    </div>
  );
}
