/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Button
                color="transparent"
                href="https://www.facebook.com/CustomPoolsLandscapesRVA/"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-facebook"} />
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button
                color="transparent"
                href="https://www.instagram.com/custompoolsandlandscapesofrva/"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-instagram"} />
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button
                color="transparent"
                href="https://www.houzz.com/professionals/swimming-pool-builders/custom-pools-and-landscapes-of-richmond-pfvwus-pf~1350566327?"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-houzz"} />
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button
                color="transparent"
                href="https://g.page/CustomPoolsLandscapesRVA?gm"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-google"} />
              </Button>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
