import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { title } from "assets/jss/material-kit-react.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import poolInstallation from "assets/img/pool-installation.jpg";
import fireplaces from "assets/img/fireplaces.jpg";
import driveways from "assets/img/driveways.jpg";
import fountains from "assets/img/fountains.jpg";
import landscaping from "assets/img/landscaping.jpg";
import outdoorKitchens from "assets/img/outdoor-kitchens.jpg";
import patios from "assets/img/patios-pool-decks.jpg";
import walls from "assets/img/wall.jpg";
import lighting from "assets/img/lighting.jpg";
import more from "assets/img/more.jpg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
  },
  ...modalStyle,
});

const useStyles = makeStyles(styles);

export default function ServicesSection() {
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);
  const [modalContent, setmodalContent] = React.useState(null);

  const images = [
    {
      url: poolInstallation,
      title: "Pool & Spa Installation",
      width: "32.8%",
      details: (
        <>
          <h3>In-ground Pool Installation</h3>
          We offer both traditional gunite (concrete) pools and fiberglass
          pools. Our design team can help you select which is best for you.
          <h4>Gunite Pools</h4>
          Concrete pools are the traditional style in-ground pool.
          <ul>
            <li>
              Pro: Design is unlimited. This is the biggest selling point of
              gunite pools. Con: Slow installation. A gunite pool project will
              take several months.
            </li>
            <li>
              Con: Expensive. Installation is more expensive, and maintenance is
              required as concrete decays over time.
            </li>
          </ul>
          <h4>Fiberglass Pools</h4>
          We specialize in fiberglass pool installation. We are the Central
          Virginia representative for{" "}
          <a
            href="https://www.lathampool.com/"
            target="_blank"
            rel="noreferrer"
          >
            Latham
          </a>{" "}
          pools; visit their site for pool options and ideas!
          <ul>
            <li>
              Pro: Very quick installation. A typical project can have the pool
              installed in days, with the surrounding hardscaping done in a
              couple weeks. Pro: Relatively inexpensive installation. Little
              future maintenance and repair cost, and a lifetime warranty.
            </li>
            <li>
              Con: Choice is limited to pre-fabricated designs. This is not a
              huge issue (Latham alone offers 92 options), but clients with deep
              or specifically-shaped pools in mind will likely need gunite. If
              you want to do some research on your own, the links here and here
              are discussions of the comparative benefits of each type of pool.
            </li>
          </ul>
          <h3>Hot Tubs {"&"} Spas</h3>
          In addition to installing gunite and fiberglass pools, we also install
          custom-designed and prebuilt hot tubs and spas.
        </>
      ),
    },
    {
      url: driveways,
      title: "Driveways",
      width: "32.8%",
      details: (
        <>
          We{"'"}ve installed professionally paved driveways, gravel driveways,
          and even custom cobblestone. Our landscape architects will design an
          aesthetic driveway that meets your needs, no matter how many vehicles
          or how low profile you want it to be. Don{"'"}t struggle with the
          narrow driveway your house was built with; a well-designed driveway
          will improve your quality of life a surprising amount.
        </>
      ),
    },
    {
      url: outdoorKitchens,
      title: "Outdoor Kitchens & Pizza Ovens",
      width: "32.8%",
      details: (
        <>
          <h3>Outdoor Kitchens {"&"} Bars</h3> Whatever your vision, our
          experienced design team can make it a reality. Our crew has built and
          / or installed:
          <ul>
            <li>
              Grills. Encased in stone, topped with marble, however you like.
            </li>
            <li>Fridges. Easy access for summer cookouts.</li>
            <li>Sinks. We can hook running water up wherever your kitchen.</li>
            <li>Pizza Ovens. We even build them custom!</li>
            <li>Even Tiki Bars.</li>
          </ul>
          <h3>Pizza Ovens</h3>
          We make our own, custom pizza ovens in-house on demand. Our owner has
          one installed at his house - the family loves it!
          <ul>
            <li>Tested. Each oven is installed and tested at 1000℉. </li>
            <li>
              Guaranteed. Our years of experience in concrete design and molding
              allow us to guarantee this product{"'"}s resilience.
            </li>
            <li>Choice. We also install other pizza oven brands!</li>
          </ul>
        </>
      ),
    },
    {
      url: fireplaces,
      title: "Fireplaces & Fire Pits",
      width: "32.8%",
      details: (
        <>
          <h3>Fireplaces</h3> Of all the works of masonry we create, our
          fireplaces often stand out as the most intricate and beautiful. We
          strongly consider adding one to any outdoor patio or seating area.
          <h3>Fire Pits</h3> Less intrusive than a fireplace, a fire pit is
          always an excellent centerpiece for an outdoor seating arrangement. We
          build in-ground stone and brick fire pits, but can also install them
          pre-fabricated. We also work with a Richmond-area metal sculptor to
          offer one-of-a-kind pieces. Ask about it!
        </>
      ),
    },
    {
      url: fountains,
      title: "Fountains & Water Features",
      width: "32.8%",
      details: (
        <>
          Our experts love installing water features! From fully functional
          fountains to pool grotto waterfalls, we can make your dreams reality.
        </>
      ),
    },
    {
      url: landscaping,
      title: "Planting, Transplanting, & Landscape Design",
      width: "32.8%",
      details: (
        <>
          <h3>Planting {"&"} Transplanting</h3>
          Our owner Jim is the proud winner of multiple Maymont Flower and
          Garden Show awards, including Best in Show. Jim will personally help
          you select the perfect plants to bring your yard to life. Whether you
          want new plantlife brought in from a nursery or us to move your
          existing plantlife around a new yard design, we have you covered.
          <h3>Landscape Design</h3>
          Our in-house landscape architects are eager to work with you to design
          your dream yard. Send us a message for a free consulation!
        </>
      ),
    },
    {
      url: patios,
      title: "Patios & Pool Decks",
      width: "32.8%",
      details: (
        <>
          We have the best masons in Virginia, and this is where they really
          shine. We install pool decks and patios of nearly any type of stone to
          fit any shape or design.
        </>
      ),
    },
    {
      url: lighting,
      title: "Lighting",
      width: "32.8%",
      details: (
        <>
          Make your yard look great at night, and enjoy late summer nights
          outdoors! Our certified electricians can install a variety of
          landscape lighting fixtures and devices. We install automated systems,
          manual systems, and everything in between.
        </>
      ),
    },
    {
      url: walls,
      title: "Retaining Walls & Grading",
      width: "32.8%",
      details: (
        <>
          <h3>Retaining Walls</h3> A beautiful stone retaining wall is the
          perfect way to add a patio to a sloped yard or even just raise the
          yard itself. Reclaim your outdoor living space! No matter how steep
          your grade, our experts have done it before.
          <h3>Grading {"&"} Site Work</h3>
          Normally the first step in any project, we have a ton of experience
          grading yards and job sites of all sizes, from residential to major
          commericial projects. Have an unusable backyard on a hillside? Let us
          reclaim your yard for you!
        </>
      ),
    },
    {
      url: more,
      title: "...and More",
      width: "99.1%",
      details: (
        <>
          We offer countless other services and options for customization,
          including stone fabrication, carpentry, and custom installation.
          Whatever your vision, our experienced design team can make it a
          reality. Our crew has built and installed:
          <ul>
            <li>
              Actual Naval guns. Check out our work at{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.yelp.com/biz_photos/fort-nelson-park-portsmouth"
              >
                Portsmouth{"'"}s Fort Nelson Park
              </a>
              !
            </li>
            <li>Pergolas, gazebos, and tiki bars.</li>
            <li>Statues, fountains, and water features.</li>
            <li>
              Custom pizza ovens. We design and craft incredible pizza ovens
              in-house.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Services We Offer</h2>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <div className={classes.images}>
              {images.map((image) => (
                <>
                  <ButtonBase
                    key={image.title}
                    className={classes.imageWrapper}
                    style={{
                      width: image.width,
                      margin: "2px 2px 2px 2px",
                    }}
                    onClick={() => {
                      setmodalContent(image);
                      setClassicModal(true);
                    }}
                  >
                    <div
                      className={classes.imageSrc}
                      style={{
                        backgroundImage: `url(${image.url})`,
                      }}
                    />
                    <div className={classes.imageBackdrop} />
                    <div className={classes.imageButton}>
                      <h3 className={classes.imageTitle}>
                        {image.title}
                        <div className={classes.imageMarked} />
                      </h3>
                    </div>
                  </ButtonBase>
                </>
              ))}
              <Dialog
                classes={{
                  root: classes.center,
                  paper: classes.modal,
                }}
                open={classicModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setClassicModal(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle
                  id="classic-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <IconButton
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => setClassicModal(false)}
                  >
                    <Close className={classes.modalClose} />
                  </IconButton>
                  <h4 className={classes.modalTitle}>{modalContent?.title}</h4>
                </DialogTitle>
                <DialogContent
                  id="classic-modal-slide-description"
                  className={classes.modalBody}
                >
                  {modalContent?.details}
                </DialogContent>
              </Dialog>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
