import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { Phone, Email } from "@material-ui/icons";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ContactForm from "components/Contact/ContactForm.js";

import styles from "assets/jss/material-kit-react/views/contactPage.js";

import image from "assets/img/pool2.jpg";

const useStyles = makeStyles(styles);

export default function ContactPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <CardHeader color="success" className={classes.cardHeader}>
                  <h4>Request a Free Consultation</h4>
                </CardHeader>
                <div className={classes.socialLine}>
                  <Button href="tel:+1-804-502-1733" color="success">
                    <Phone className={classes.icons} /> Call Us
                  </Button>
                  <Button
                    href="mailto:contact@customlandscapesofrichmond.com"
                    color="success"
                  >
                    <Email className={classes.icons} /> Email Us
                  </Button>
                </div>
                <ContactForm />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
